
*{
    box-sizing: border-box;
    padding: 0;
    margin:0;
}
.section1{
    width: 100%;
    height:100vh;
}
.section2{
    width: 100%;
    height:100%;
    background-color:black;
    padding-top: 20px;
    padding-bottom: 20px;
}
.section3{
    width: 100%;
    height:100%;
    font-family: 'montserrat';
    padding-top: 20px;
    padding-bottom: 20px;
}
.section4{
    width: 100%;
    height:100%;
    background-color: #00ff0d;
    padding-top: 20px;
    padding-bottom: 20px;
}
.video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.content{
    color:aliceblue;
    text-shadow:1.5px 1.5px black ;
    position:absolute;
    width: 100%;
    height: 100%;
    top:0;
    font-size: 25px;
    font-family: 'montserrat';
    padding-left: 35px;
    margin-top: 85px;
    margin-left: 10px;
}

.heading{
    font-size: 25px;
    font-style: 'montserrat';
    color:aliceblue;
}
.line{
    border-width: 3px;
    border-color: #00ff0d;
    opacity: 1;
    width:100px;
    margin:auto;
}

.image-row{
    display: flex;
    justify-content: center;
    align-items: center;
}
.image{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height:320px;
    width:250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 5px;
    margin-top: 10px;
    filter: grayscale(90%);
    color:#00ff0d;
}
.image:hover{
    filter: grayscale(0);
    transition: 0.3s;
    box-shadow: 2px 2px 20px #00ff0d;
}
.image2{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width:100%;
    height: 380px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 10px;
    filter: grayscale(90%);
    border-radius:8px;
    padding: 5px;
    color:#00ff0d;
}
.image2:hover{
    filter: grayscale(0);
    transition: 0.3s;
    box-shadow: 2px 2px 20px #00ff0d;
}
.story1{
    background-image: url('https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849368/Circular%20website/1story_lab5zp.jpg');
}
.story2{
    background-image: url('https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849358/Circular%20website/2story_vtwyud.jpg')
}
.story3{
    background-image: url('https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849359/Circular%20website/3story_abkdwy.jpg');
}
.story4{
    background-image: url('https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849359/Circular%20website/4story_amx8mg.jpg');
}
.story5{
    background-image: url('https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849359/Circular%20website/5story_lbpbfv.jpg');
}
.story6{
    background-image: url('https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849360/Circular%20website/6story_m2aaof.jpg');
}
.story7{
    background-image: url('https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849360/Circular%20website/7story_fglbbf.jpg');
}
.story8{
    background-image: url('https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849398/Circular%20website/story8_vggwax.jpg');
}
.story9{
    background-image: url('https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849361/Circular%20website/9story_shqotq.jpg');
}
.story10{
    background-image: url('https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849362/Circular%20website/10story_s7dfzs.jpg');
}
.story11{
    background-image:url('https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849396/Circular%20website/story11_hkzm6i.jpg');
}
.name{
    font-size: 20px;
    font-family: 'montserrat';
    text-shadow: 3px 3px 5px black;
}
.sec3-head{
    font-size: 25px;
    font-weight: bold;
    padding-left: 60px;
}

.button{
    border-width: 0px;
    border-radius: 20px;
    width:150px;
    height:32px;
    font-size: 15px;
    font-family: 'montserrat';
    color:#000;
}
.button:hover{
    background-color:#00ff0d;
    transition: 0.3s;
    border: 1px solid black;   
}

.navigator{
    display: flex;
    justify-content: space-around;
}
.para{
    font-size: 15px;
    font-family: 'montserrat';
    margin-top:12px;
    padding-left: 60px;
    padding-right: 60px;
}
.text-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.logo{
    border-radius: 40px;
    width: 80px;
    height: 80px;
    margin: 10px;
}
.persons{
    width: 130px;
    height: 130px;
    border-radius: 65px ;
    margin-bottom: 10px;
}
.image-box{
    width:230px;
    height:230px;
    border-radius: 115px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 55px;
    margin-right: 55px;
}
.image-box:hover{
    box-shadow: 2px 2px 20px #00ff0d;
    transition: 0.3s;
}
@media screen and (max-width:600px){
    .video{
        max-width: 600px;
    }
    .content{
        max-width: 600px;
        color:aliceblue;
        /* text-shadow:1.5px 1.5px black ; */
        /* position:absolute;
        width: 100%;
        height: 100%;
        top:0; */
        font-size: 15px;
        /* font-family: 'montserrat'; */
       padding-left: 15px;
       padding-right: 15px;
       margin-left: 10px;
    } 
    .sm-image{
        display: flex;
        flex-direction: column;
    }
    iframe{
        width: 300px;
        height: 200px;
    }
    .heading{
        font-size: 20px;
    }
    .para{
        font-size: 12px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .together{
        font-size: 15px !important;
    }
    .image-row{
        flex-direction: column;
    }
    .image{
        height: 240px;
        width: 170px;
    }
    .image2{
        width:100%;
        height: 250px;
    }
    .sec3-head{
        font-size:20px;
        padding-left: 30px;
    }
    .sm-adbox{
        flex-direction: column;
        align-items: center;
    }
    .text-logo{
        flex-direction: column;
    }
    .navigator{
        display: flex;
        flex-wrap: wrap;
    }
    .button{
        margin: 10px;
        font-size: 12px;
        width:100px;
        height:25px;
    }
}