.lbox-image{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.profile{
    padding-left: 60px;
    padding-right: 60px;
}
.lperson{
    width: 220px;
    height: 220px;
    border-radius: 50% ;
    margin-bottom: 15px;
}
.lpara{
    font-size: 20px;
    font-family: 'montserrat';
    margin-top:12px;
    padding-left: 60px;
    padding-right: 60px;
}
.h-line{
    border-width: 3px;
    border-color: #00ff0d;
    margin-bottom: 30px;
    margin-top: 0;
    opacity: 1;
    
}
.desc{
    font-size: 15px;
    font-family: 'montserrat';
}
.more{
    width:100px;
    height: 30px;
    border-width: 0px;
    background-color: transparent;
    color:blue;
    text-align: left;
}
.more:hover{
    color:aliceblue;
}

@media screen and (max-width:600px){
    .h-line{
        width:250px;
    }
    .name-line{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .lbox-image{
        flex-direction: column;
        align-items: center;
    }
    .lperson{
        width: 150px;
        height: 150px;
    }
    .lpara{
        padding-left: 25px;
        padding-right: 25px;
        font-size: 16px;
        text-align: center;
    }
    .pname{
        font-size: 18px !important;
    }
    .pdesg{
        font-size: 15px !important;
    }
    .pemail{
        font-size: 15px !important;
    }
    .field-video{
        width: 270px !important;
        height: 200px !important;
    }
    .trip-video{
        width: 280px !important;
        height: 220px !important;
    }
}