@font-face{
    font-family:'montserrat';
    src:local("montserrat"),url('../fonts/Montserrat-Regular.ttf')
  }

  .nav-bar{
    height:15vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    color:aliceblue;
    top:0;
    position:relative;
    padding: 10px;
    z-index: 10;
  }
  #menu-bar{
    display: none;
  }
  label{
    cursor:pointer;
    display: none;
  }
  .logo{
    height:80px;
    width:80px;
  }
  .logo-text{
    display: flex;
    align-items: center;
  }
 
  .main-head{
    font-size: 32px;
    font-family: "montserrat";
    color:aliceblue;
  }
  .list{
    list-style-type:none;
    display: flex;
  }
  .link{
    text-decoration: none;
  }
  .list-items{
    font-family:'montserrat';
    font-weight: bold;
    font-size: 18px;
    margin-right: 30px;
    color:aliceblue;
  }
  .main-head:hover{
    color:#00ff0d;
    transition: 0.5s; 
  }
  .list-items:hover{
    color:#00ff0d;
    transition: 0.5s;    
  }
  .dropdown{
    position: relative;
  }
  .focus-items{
    display: none;
    position: absolute;
    background-color:aliceblue;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;   
  }
  .focus-items .link{
    color:black;
    padding: 5px 7px;
    font-size: 15px;
    font-family: 'montserrat';
    text-decoration: none;
    display: flex;
    flex-direction: column;
    
  }
  .focus-items .link:hover{
    background-color: black;
    color: #00ff0d;
    transition: 0.5s; 
  }
  
  .dropdown:hover > .focus-items{
    display: block;
    
  }

@media screen and (max-width:600px){
  .nav-bar{
    height: 12vh;
    z-index: 10;
  }
  .logo{
    height:45px;
    width:45px;
  }
  .main-head{
    font-size: 25px;
    flex-wrap: nowrap;
  }
  .list{
    position: absolute;
    top:12vh; left: 0; right: 0;
    flex-direction: column;
    background-color: black;
    display: none;
    padding-bottom: 15px;
  }
  .list-items{
    font-size: 15px;
    color: aliceblue;
    margin-bottom: 10px;
    padding-top: 10px;
  }
  .focus-items{
    position: relative;
    width: 90%;
  }
  .focus-items .link{
    font-size:14px;
  }
  label{
    display: initial;
  }
  label:hover{
    color: #00ff0d;
  }
  #menu-bar:checked ~ .list{
    display: block;
  }
}