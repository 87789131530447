*{
    margin: 0;
    padding: 0;
}
.sec2{
    width: 100%;
    height:100%;
    background-color:black;
    padding-top: 20px;
    padding-bottom: 20px;
}
.paragh{
    font-size: 15px;
    font-family: 'montserrat';
    color:aliceblue;
    padding-left: 60px;
    padding-right: 60px;
}
.card-box{
    display: flex;
    justify-content: space-around;
    font-size:15px;
    font-family: 'montserrat';
    color:black;
    width:100%;
    height:300px;
    margin-top: 20px;
    margin-bottom: 25px;
}
.text-box{
    margin:0;
    padding:0;
    width: 500px;
    height: 300px;
}
.btnlr{
    width:120px;
    height: 32px;
    border:1px solid #00ff0d;
    background-color: black;
    color:aliceblue;
}
.btnlr:hover{
    background-color: #00ff0d;
    color:black;
    transition: 0.3s;

}
.pic{
    width:400px;
    height:300px;
    border:2px solid black;
    border-radius: 15px;
    box-shadow: 2px 2px 20px #00ff0d;
}
.humanpic{
    width:400px;
    height:300px;
    border:2px solid black;
    border-radius: 15px;
    box-shadow: 2px 2px 20px #00ff0d;
}
.sec2-head{
    color:black;
    font-size: 25px;
    font-family: 'montserrat';
}
.text-image-box{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 100px;
    margin-right: 100px;
}
.ghmc{
    width: 450px;
    height: 300px;
    margin:15px;
}
.ghmc-text{
    color: aliceblue;
    font-size: 15px;
    font-family: "montserrat";
    display: flex;
    justify-content:center;
    align-items: center;
}
.seemore{
    width:100px;
    height: 30px;
    border-width: 0px;
    background-color: transparent;
    color:blue;
    text-align: left;
}
.seemore:hover{
    color: #00ff0d;
}
.image-arrange{
    display: flex;
    justify-content: center;
}
.black-image{
    width:500px;
    height:300px;
    border:2px solid white;
    border-radius: 15px;
    margin-right:15px;
    margin-left: 15px;
}
.hline{
    display: none;
}


@media screen and (max-width:600px){
    .sec2-head{
        font-size: 20px;
    }
    .sec2{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .sec2-head{
        font-size: 20px;
    }
    .paragh{
        font-size: 12px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .image-arrange{
        flex-direction: column;
        align-items: center;
    }
    .black-image{
        width: 300px !important;
        height: 180px;
        margin-bottom: 15px;
    }
    .hline{
        display: block;
    }
    .text-image-box{
        flex-direction: column;
        align-items: center;
    }
    .water{
        padding-left:10px !important;
        text-align: center;
    }
    .ghmc{
        width: 300px;
        height: 180px;
    }
    .ghmc-text{
        flex-direction: column;
        font-size: 14px;
    }
    .card-box{
        flex-wrap: wrap;
        align-items: center;
        font-size: 12px;
        height: 360px;
    }
    .card-box h1{
        font-size: 20px !important;
        font-weight: bold;
    }
    .text-box{
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 15px;
        width: 250px;
        height: 200px;
        order: 1 !important;
    }
    .humanpic{
        width: 200px;
        height: 140px;
        order: 2 !important;
    }
    .edubox{
        height: 480px !important;
    }
    .healthbox{
        height: 500px !important;
    }
    .qualitybox{
        height: 410px !important;
    }
    .forspace{
        margin-bottom: 150px;
    }
    .forskillsspace{
        margin-bottom: 160px;
    }
    .forhealthspace{
        margin-bottom: 180px;
    }
    .forquality{
        margin-bottom: 100px;
    }
    .pic{
        width:250px !important;
        height:160px !important;
        order: 2 !important;
    }
    .btnlr{
        width: 90px;
        height: 30px;
    }
}