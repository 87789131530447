.cover-imageC{
    background-image: url('https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849363/Circular%20website/Circulareconomy_ul7vt5.png');
    width: 100%;
    height: 60vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center; 
}
.cover-imageN{
    background-image: url('https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849388/Circular%20website/Nature_zgsy4z.jpg');
    width: 100%;
    height: 60vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center; 
}
.cover-imageQ{
    background-image: url('https://res.cloudinary.com/dh4bpcja3/image/upload/v1685881492/Circular%20website/quality/Quality_zpzf6e.jpg');
    width: 100%;
    height: 60vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center; 
}
.cover-imageT{
    background-image: url('https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849395/Circular%20website/Transparency_bljpif.jpg');
    width: 100%;
    height: 60vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center; 
}
.cover-imageM{
    background-image: url('https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849384/Circular%20website/medicine_cover_image_jfb4tp.jpg');
    width: 100%;
    height: 60vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center; 
}
.cover-imageH{
    background-image: url('https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849376/Circular%20website/hospitality_cover_image_g0vplt.jpg');
    width: 100%;
    height: 60vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center; 
}
.cover-imageHe{
    background-image: url('https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849375/Circular%20website/hepatology_cover_image_ujw1dj.png');
    width: 100%;
    height: 60vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center; 
}
.cover-imageG{
    background-image: url('https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849373/Circular%20website/gynaecology_cover_image_bzgscv.jpg');
    width: 100%;
    height: 60vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center; 
}
.cover-imageD{
    background-image: url('https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849363/Circular%20website/desc_cover_dzga8g.jpg');
    width: 100%;
    height: 60vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center; 
}
.cover-imageS{
    background-image: url('https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849394/Circular%20website/smae_cover_kthd54.jpg');
    width: 100%;
    height: 60vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center; 
}
.cover-imageSk{
    background-image: url('https://res.cloudinary.com/dh4bpcja3/image/upload/v1685876681/Circular%20website/skills/skills_cover_hybi4l.jpg');
    width: 100%;
    height: 60vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center; 
}
.cover-imageO{
    background-image: url('https://res.cloudinary.com/dh4bpcja3/image/upload/v1685870099/Circular%20website/wallpaperflare.com_wallpaper_gdr6fe.jpg');
    width: 100%;
    height: 60vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center; 
}
.cover-imageCC{
    background-image: url('https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849362/Circular%20website/caregood_cover_khinmq.jpg');
    width: 100%;
    height: 60vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center; 
}
.redirect{
    text-decoration: none;
    color:black;
}
.image-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.cimpic{
    width: 25%;
    height: 25%;
    border:2px solid white;
    border-radius: 15px;
}
.medpich{
    width: 450px;
    height:300px;
    border:2px solid white;
    border-radius: 15px;
}

.medpicv{
    width:340px;
    height:600px;
    border:2px solid white;
    border-radius: 15px;
}
.nepic{
    width: 300px;
    height:450px;
    border:2px solid white;
    border-radius: 15px;
}

@media screen and (max-width:600px){
    .cover-imageC{
        max-width: 600px;
        text-align: center;
    }
    .cover-imageN{
        max-width: 600px;
        text-align: center;
    }
    .cover-imageM{
        max-width: 600px;
        text-align: center;
    }
    .cover-imageH{
        max-width: 600px;
        text-align: center;
    }
    .cover-imageHe{
        max-width: 600px;
        text-align: center;
    }
    .cover-imageG{
        max-width: 600px;
        text-align: center;
    }
    .cover-imageSk{
        max-width: 600px;
        text-align: center;
    }
    .cover-imageQ{
        max-width: 600px;
        text-align: center;
    }
    .cover-imageT{
        max-width: 600px;
        text-align: center;
    }
    .skills-pic{
        height: 225px !important;
        width: 160px !important;
    }
    .neelmani{
        height: 300px !important;
    }
    .medpich{
        width:280px !important;
        height:190px !important;
    }
    .paper-bag-pic{
        width: 220px !important;
    }
    .cimpic{
        width:210px !important;
        height:250px;
        margin-bottom: 20px;
    }
    .ripal-pics{
        flex-wrap: wrap;
    }
    .font-head{
        font-size: 18px !important;
    }
    .aranyapic{
        width: 330px !important;
    }
    .other-head{
        font-size: 25px !important;
    }
    .image-container{
        flex-wrap: wrap;
    }
    .cir-video{
        width: 250px;
        height: 300px;
    }
    .cir-lastpic{
        height: 150px;
        width:250px !important;
    }
    .nature-video{
        width: 270px !important;
        height:200px !important;
    }
    .nepic{
        width: 180px;
        height:255px;
        margin-bottom: 15px;
    }
    .medpicv{
        width:200px;
        height:400px;
    }
    .abhay-pics{
        display: flex;
        flex-direction: column;
    }
    .human-font{
        font-size: 25px !important;
    }
}