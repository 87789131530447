.cover-image{
    background-image: url('https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849377/Circular%20website/healthcover_nuhxsg.jpg');
    width: 100%;
    height: 60vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center; 
}
.cover-text{
    font-family: 'montserrat';
    font-size: 100px;
    color:black;
    font-weight: bold;
    text-shadow: 2px 2px 20px aliceblue;
    opacity: 0.8;
}

.text-head{
    font-size: 20px;
    font-family: 'montserrat';
    color:#00ff0d;
}
.paragraph{
    font-size: 15px;
    font-family: 'montserrat';
    color:aliceblue;
}
.images-tag{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.big-image{
    max-width: 700px;
    border:2px solid black;
    border-radius: 15px;
    margin-bottom: 10px;
}
.box-image{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
}
.image-text{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-left: 60px;
    padding-right: 60px;
}
.person{
    width: 200px;
    height: 200px;
    border-radius: 100px ;
    margin-bottom: 15px;
}
.image-para{
    font-size: 15px;
    font-family: 'montserrat';
}
.small-image{
    width: 100px;
    height:100px;
    border-radius: 50px;
    margin:15px;
}
.audio-head{
    font-size: 20px;
    font-family: 'montserrat';
}
.audio-tag{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 400px;
    height:120px;
    border-radius:60px;
    box-shadow: 2px 2px 10px black;
    box-sizing: border-box;
    background-color: white;
    padding: 10px;
}
.cardbox{
    width:100%;
    height:300px;
    border-radius: 10px;
    box-shadow: 2px 2px 10px black;
    box-sizing: border-box;
}
.image-card{
    width: 320px;
    height:300px;
    border-radius: 10px;
}
.textbox{
    font-family: 'montserrat';
    padding: 20px;
}
.break{
    display: none;
}
@media screen and (max-width:600px) {
    .cover-image{
        max-width: 600px;
        text-align: center;
    }
    .cover-text{
        font-size: 40px;
    }
    .image-para{
        font-size: 12px;
    }
    .images-tag{
        flex-wrap: wrap;
    }
    .image-text{
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;
    }
    .paragraph{
        font-size: 12px;
    }
    .big-image{
        text-align: center !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        height: 160px;
    }
    .person{
        height: 150px;
        width: 150px;
    }
    .break{
        display: block;
    }
    .cardbox{
        flex-direction: column;
        width: 260px;
        height: 100% !important;
    }
    .image-card{
        width: 260px;
        height: 300px;
    }
    .audio-head{
        font-size: 18px;
    }
    .audio-tag{
        width: 280px;
        height: 80px;
    }
    .small-image{
        width: 60px;
        height: 60px;
    }
}