.partner-cover-image{
    background-image: url('https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849389/Circular%20website/partnerCoverImage_g5tynm.jpg');
    width: 100%;
    height: 60vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px;
    padding: 15px;
    width: 350px;
    
}
.logo-box:hover{
    box-shadow: 2px 2px 20px #00ff0d;
    transition: 0.3s;
    border-radius: 15px;
}
.co-logo{
    width:100px;
    height:100px;
    border-radius: 50%;
    margin-right: 15px;
}
.logo-heading{
    font-family: 'montserrat';
    font-size: 30px;
    font-weight: bold;
}
.logo-name{
    font-family: 'montserrat';
    font-size: 20px;
}
.p-container{
    padding-left: 60px;
    padding-right: 60px;
    display: flex;
    justify-content: space-around;
}
.gp{
    width: 300px;
    height:700px;
    border-radius: 10px;
    border:2px solid black;
    order:1;
}
.gpp{
    order: 2;
}
.logo-text{
    display: flex;
    justify-content: space-around;
    margin:25px;
}
.cg-logo{
    width:280px;
    height:100px;
   
}

@media screen and (max-width:600px) {
    .partner-cover-image{
        max-width: 600px;
        display: flex;
        justify-content: center;
    }
    .p-container{
        padding-left: 10px;
        padding-right:10px;
        display: flex;
        flex-wrap: wrap;
        text-align: center;  
    }
    .gp{
        order:2;
        width: 150px;
        height: 340px;  
    }
    .gpp{
        order:1;
    }
    .logo-text{
        flex-wrap: wrap;
    }
    .logo-box{
        width: 280px;
    }
    .logo-heading{
        font-size: 20px;
    }
    .logo-name{
        font-size: 15px;
    }
    .cg-logo{
        width: 240px;
    }
}