.main-heading{
    font-family: 'montserrat';
    font-size: 30px;
    padding-left: 60px;
    padding-right: 60px;
}
.sub-heading{
    font-family: 'montserrat';
    font-size: 15px;
    padding-left: 60px;
    padding-right: 60px;
    font-weight: bold;
}
.abhay-title{
    display: flex;
    justify-content: center;
    align-items: center;
}
.image-gallery-wrapper {
    margin-top: 5rem;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    border: 1px solid rgb(146, 129, 242);
    box-shadow: #2b15453d 1px 10px 10px 5px;
  }
  @media screen and (max-width:600px){
    .image-gallery-wrapper{
        max-width: 600px;
    } 
    .main-heading{
        font-size: 20px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .sub-heading{
        padding-left: 30px;
        padding-right: 30px;
    }
  }