.footer{
    position: relative;
    bottom:0;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-family: 'montserrat';
    background-color: black;
    padding:10px;
    color:aliceblue;
    align-items: center;
}

#res:hover{
    color: #00ff0d;
    transition: 0.5s;
}

.news{
    text-decoration: none;
    color:aliceblue;
    font-size: 15px;
}
@media screen and (max-width:600px){
    .footer{
        flex-direction: column-reverse;
    }
    .footerIcon{
        display: flex;
        margin-top: 10px;

    }
    
    .footerNews{
        display: flex;
        justify-content: flex-end;
        margin-left: 60px;
    }
    .news{
        margin-right: 8px;
    }
}